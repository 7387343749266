<template>
  <div>
    <button
      v-if="!!next"
      class="btn btnMaim btnMaim_wide"
      :class="{
        disabled: disabled,
      }"
      @click="run"
    >
      {{ next.text }}<i class="far fa-long-arrow-right"></i>
    </button>
    <div class="also">
      <p>
        Вы можете получить консультацию, связавшись с нашими менеджерами по
        телефону
      </p>
      <a href="tel:+79999999999">+7 999 999 99 99</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    next: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    run() {
      if (this.disabled) {
        return;
      }
      this.$emit("click");
      this.$router.push({ name: this.next.name });
    },
  },
};
</script>
