<template>
  <div>
    <div class="capabilitie">
      <span>Цена:</span>
      от {{ totalPrice }} рублей
      <i
        v-tooltip="{ content: settings.tips.tip1 }"
        class="fal fa-info-circle pointer"
      ></i>
    </div>
    <div class="capabilitie">
      <span>Производство:</span>
      от {{ totalDays }} рабочих дней
      <i
        v-tooltip="{ content: settings.tips.tip2 }"
        class="fal fa-info-circle pointer"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapFields(["settings", "cars"]),
    totalPrice() {
      return Math.round(
        this.cars
          .map((car) => {
            return (
              parseInt(car.overallDimension?.variants[0]?.total_price || 0) *
              parseInt(car.vcq?.quantity)
            );
          })
          .reduce((sum, item) => sum + item, 0)
      );
    },
    totalDays() {
      return Math.ceil(
        this.cars
          .map((car) => {
            return (
              parseInt(car.overallDimension?.variants[0]?.total_period || 0) *
              parseInt(car.vcq?.quantity)
            );
          })
          .reduce((sum, item) => sum + item, 0)
      );
    },
  },
  methods: {
    calcTotalDays(car) {
      const t = 0.075;
      const carArea = parseFloat(car.vcq?.variant?.area || 1);
      if (carArea < 40) {
        return 3;
      }
      return parseFloat(car.vcq?.variant?.area || 1) * this.calcKi(car) * t;
    },
    getFilmPrice(car) {
      return parseFloat(
        this.settings.films.find((f) => f.id === car.filmId)?.price1 || 1
      );
    },
    calcTotalCarPrice(car) {
      return (
        parseFloat(car.vcq?.variant?.area || 1) *
        this.calcKi(car) *
        this.getFilmPrice(car)
      );
    },
    calcKi(car) {
      const activeViews = this.getActiveCarViews(car);
      const coefficients = {
        top: 0.23,
        right: 0.28,
        back: 0.1,
        left: 0.28,
        front: 0.11,
      };
      return activeViews
        .map((view) => {
          return (
            parseFloat(coefficients[view]) * parseFloat(this.calcPiv(car, view))
          );
        })
        .reduce((sum, item) => sum + item, 0);
    },
    calcPiv(car, view) {
      const _half = parseFloat(this.settings.coefficients.p_half);
      const _three_fourths = parseFloat(
        this.settings.coefficients.p_three_fourths
      );
      const _window = parseFloat(this.settings.coefficients.p_window);
      const part = car.views[view].part;

      if (["front", "back", "top"].includes(view)) {
        return 1;
      } else if (["left", "right"].includes(view)) {
        switch (part) {
          case "full":
            return 1;
          case "half":
            return _half;
          case "two_thirds":
            return _three_fourths;
          case "glass":
            return _window;
          default:
            return 0;
        }
      } else {
        return 0;
      }
    },
    getActiveCarViews(car) {
      return Object.keys(car.views)
        .map((view) => {
          const data = car.views[view];
          return data.backgrounds?.length > 0 || data.figures?.length > 0
            ? view
            : false;
        })
        .filter((view) => !!view);
    },
  },
};
</script>
