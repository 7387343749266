<template>
  <div class="card">
    <h2>Пленка</h2>
    <div class="table_nona color-1">
      <table class="dropdown">
        <thead>
          <tr>
            <th class="item-1">Пленка</th>
            <th class="item-2">Гарантия</th>
            <th class="item-3">Срок службы</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="shownMenu = !shownMenu">
            <td>{{ film && film.name }}</td>
            <td>{{ film && film.guarantee }}</td>
            <td>
              {{ film && film.life }}
              <a href="#" class="dropbtn"><i class="far fa-angle-down"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="shownMenu"
        class="dropdown-content"
        @mouseleave="shownMenu = !shownMenu"
      >
        <table>
          <tbody>
            <template v-for="f in filteredFilms">
              <tr
                :key="f.id"
                :class="{
                  active: f.id == filmId,
                }"
                @click="setFilmId(f.id)"
              >
                <td>{{ f.name }}</td>
                <td>{{ f.guarantee }}</td>
                <td>{{ f.life }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <span>Отфильтровано пленок: {{ filteredFilms.length }}</span>
    </div>
    <div class="properties">
      <div class="propertie">
        <p>Свойства</p>
        <select v-model="filter.type">
          <option
            v-for="(v, k) in availableProps.types"
            :key="k"
            :value="k"
            :disabled="!v"
          >
            {{ k }}
          </option>
        </select>
      </div>
      <div class="propertie">
        <p>Производитель</p>
        <select
          v-model="filter.manufacturer"
          :class="{ unselected: !filter.manufacturer }"
        >
          <option :value="null">Любой производитель</option>
          <option
            v-for="(v, k) in availableProps.manufacturers"
            :key="k"
            :value="k"
            :disabled="!v"
          >
            {{ k }}
          </option>
        </select>
      </div>
      <div class="propertie">
        <p>Срок эксплуатации пленки (года)</p>
        <select
          v-model.number="filter.life"
          :class="{ unselected: !filter.life }"
        >
          <option :value="null">Любой срок эксплуатации</option>
          <option
            v-for="(v, k) in availableProps.lifes"
            :key="k"
            :value="k"
            :disabled="!v"
          >
            {{ k }}
          </option>
        </select>
      </div>
    </div>
    <p class="attention">
      <label>
        Применить выбранную пленку ко всем авто
        <input v-model="isFilmForAll" type="checkbox" />
      </label>
    </p>
    <p class="attention">
      <label
        ><span style="color: red">*</span> Не обязательно к заполнению
      </label>
    </p>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { mapFields, createHelpers } from "vuex-map-fields";

const clone = require("rfdc")();

const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

const emptyFilter = {
  type: "Стандартная",
  manufacturer: null,
  life: null,
};

export default {
  data() {
    return {
      shownMenu: false,
      filter: { ...emptyFilter },
      forAll: true,
    };
  },
  computed: {
    ...mapFields(["settings", "isFilmForAll"]),
    ...mapCarFields(["filmId"]),
    films() {
      return this.settings.films;
    },
    film() {
      return this.films.find((f) => f.id === this.filmId);
    },
    filteredFilm() {
      return this.filteredFilms.find((f) => f.id === this.filmId);
    },
    allProps() {
      return this.films.reduce(
        (a, v) => {
          a.types[v.type] = false;
          a.manufacturers[v.manufacturer] = false;
          a.lifes[v.life] = false;
          return a;
        },
        { types: {}, manufacturers: {}, lifes: {} }
      );
    },
    filteredFilms() {
      return this.filterFilms(this.filter);
    },
    countFilteredFilms() {
      return this.filteredFilms.length;
    },
    availableProps() {
      const props = clone(this.allProps);
      const propsFromFilms = (prop, films) =>
        films.reduce((a, v) => {
          const valProp = v[prop.slice(0, -1)];
          a[valProp] = true;
          if (prop === "types") a["Стандартная"] = true;
          return a;
        }, props[prop]);
      ["types", "manufacturers", "lifes"].forEach((ps) =>
        propsFromFilms(
          ps,
          this.filterFilms({ ...this.filter, [ps.slice(0, -1)]: null })
        )
      );
      return props;
    },
  },
  watch: {
    film: {
      handler(val) {
        this.filter.type = val?.type || emptyFilter.type;
      },
      immediate: true,
    },
    filteredFilms: "initFilmId",
    countFilteredFilms(val) {
      if (!val) {
        this.filter = { ...emptyFilter };
      }
    },
  },
  mounted() {
    const defaultFilmId = this.filteredFilms?.[0]?.id;
    if (defaultFilmId) {
      this.setFilmId(defaultFilmId);
    }
  },
  methods: {
    ...mapMutations(["updateAllCarsFilmId"]),
    initFilmId() {
      if (!this.filteredFilm) this.setFilmId(this.filteredFilms[0]?.id);
    },
    setFilmId(fId) {
      this.shownMenu = false;
      this.filmId = fId;
      if (this.isFilmForAll) this.updateAllCarsFilmId(fId);
    },
    filterFilms(filter) {
      return this.films.filter(
        (f) =>
          (filter.type === null || filter.type === f.type) &&
          (filter.manufacturer === null ||
            filter.manufacturer === f.manufacturer) &&
          (filter.life === null || filter.life === f.life)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.propertie {
  .unselected {
    border-color: lightgrey !important;
  }
  option {
    color: black;
    &:disabled {
      color: lightgrey;
    }
  }
}
</style>
