<template>
  <div>
    <h2>Автомобиль</h2>
    <div class="table_nona color-1 current_cars">
      <table>
        <thead>
          <tr>
            <th class="item-1">Модель</th>
            <th class="item-2">Цвет</th>
            <th class="item-3">Количество</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="showCarMenu = !showCarMenu">
            <td>
              {{ vcq.variant ? vcq.variant.name : "Не указана" }}
            </td>
            <td>
              <span
                class="square_color"
                :style="`background-color: #${vcq.color}`"
              ></span>
            </td>
            <td>{{ vcq.quantity }} шт</td>
            <td>
              <i class="far fa-angle-down"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="showCarMenu"
        class="dropdown-content-current-car"
        @mouseleave="showCarMenu = !showCarMenu"
      >
        <table>
          <tbody>
            <template v-for="(c, i) in cars">
              <tr
                :key="i"
                :class="{ active: carIndx == i }"
                @click="setCarIndx(i)"
              >
                <td>
                  {{ c.vcq.variant ? c.vcq.variant.name : "Не указана" }}
                </td>
                <td>
                  <span
                    class="square_color"
                    :style="`background-color: #${c.vcq.color}`"
                  ></span>
                </td>
                <td>{{ c.vcq.quantity }} шт</td>
                <td></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="items">
      <a class="switch_BIG prev" @click="prevCar">
        <span>
          <img src="@/assets/imgs/Big-prevActive.png" />
        </span>
      </a>
      <a class="switch_BIG next" @click="nextCar">
        <span>
          <img src="@/assets/imgs/Big-nextActive.png" />
        </span>
      </a>
      <div class="item">
        <div class="img">
          <img
            v-if="vcq.variant && vcq.variant.preview"
            :src="vcq.variant.preview"
            :style="`background: #${vcq.color}`"
          />
          <img v-else :src="settings.previewEmpty" />
        </div>
      </div>
    </div>
    <div class="quantity">
      Всего: <span>{{ totalCars }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mapFields, mapMultiRowFields, createHelpers } from "vuex-map-fields";

const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

export default {
  data() {
    return {
      showCarMenu: false,
    };
  },
  computed: {
    ...mapGetters(["totalCars"]),
    ...mapCarFields(["vcq"]),
    ...mapMultiRowFields(["cars"]),
    ...mapFields(["carIndx", "settings"]),
  },
  methods: {
    ...mapMutations(["nextCar", "prevCar"]),
    setCarIndx(i) {
      this.carIndx = i;
      this.showCarMenu = false;
    },
  },
};
</script>
