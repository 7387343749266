import { render, staticRenderFns } from "./__capabilities__.vue?vue&type=template&id=2789d9db&"
import script from "./__capabilities__.vue?vue&type=script&lang=js&"
export * from "./__capabilities__.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports