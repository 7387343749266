<template>
  <div>
    <Car class="card" />
    <Design class="card" />
    <Film class="card" />
  </div>
</template>

<script>
import Car from "./cards/car";
import Design from "./cards/design";
import Film from "./cards/film";

export default {
  components: {
    Car,
    Design,
    Film,
  },
};
</script>
