<template>
  <div class="checkout_card">
    <Top
      :prev="{ text: 'К выбору дизайна', name: 'constructor-car' }"
      :state="{ value: 3, text: 'Оформление заказа' }"
    />
    <Cards class="cards" />
    <Capabilities class="capabilities" />
    <Bottom
      :next="{ text: 'Получить предложение', name: 'entering-contacts' }"
      class="bottom"
    />
  </div>
</template>

<script>
import Top from "../__top__";
import Bottom from "../__bottom__";

import Cards from "./__cards__";
import Capabilities from "./__capabilities__";

export default {
  components: {
    Top,
    Bottom,
    Cards,
    Capabilities,
  },
};
</script>
