<template>
  <div>
    <h2>Дизайн</h2>
    <template v-if="design === 'individual'">
      <div class="text_top">
        Заказать уникальный дизайн от топовой московской студии
      </div>
      <img class="img-2" :src="settings.imgs.special" />
      <div class="special">
        <span class="btn design active"> Индивидуальный дизайн </span>
        <a class="btn design" @click="design = 'your'">
          У меня есть готовый дизайн
        </a>
      </div>
    </template>
    <template v-else-if="design === 'your'">
      <p class="text_top">У Вас есть свой дизайн</p>
      <img class="img-2" :src="settings.imgs.own" />
      <div class="special">
        <a class="btn design" @click="design = 'individual'">
          Индивидуальный дизайн
        </a>
        <span class="btn design active"> У меня есть готовый дизайн </span>
      </div>
    </template>
    <template v-else-if="design === 'construct'">
      <p class="text_top">Вы забрендировали автомобиль в конструкторе</p>
      <CarCanvas class="dn" @ready="createImages" />
      <div id="views"></div>
      <div class="special">
        <!--
        <a class="btn design" @click="design = 'individual'"
          >Индивидуальный дизайн</a
        >
        <a class="btn design" @click="design = 'your'">
          У меня есть готовый дизайн
        </a>-->
      </div>
    </template>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import CarCanvas from "@/pages/constructor-car/__car-canvas__.vue";

export default {
  components: { CarCanvas },
  computed: {
    ...mapFields(["settings", "design", "cars", "carIndx"]),
    viewIds() {
      return Object.keys(this.cars[this.carIndx].views ?? {});
    },
  },
  watch: {
    carIndx() {
      this.blobNames = [];
      this.blobs = [];
      const views = document.getElementById("views");
      views.innerHTML = "";
    },
  },
  data() {
    return {
      init: {
        carIndx: 0,
        viewId: "left",
      },
      viewIndx: 0,
      blobs: [],
      blobNames: [],
    };
  },
  methods: {
    restore() {
      this.carIndx = this.init.carIndx;
      this.viewId = this.init.viewId;
    },
    async createImages() {
      if (this.blobs.length >= this.countViews) return;
      const canvas = document.getElementById("c");

      const views = document.getElementById("views");
      canvas.toBlob((blob) => {
        this.blobs.push(blob);

        const img = new Image(272, 179);
        const imgURL = window.URL.createObjectURL(blob);
        img.src = imgURL;
        views.appendChild(img);
        this.blobNames.push(`${this.viewId}_${this.carIndx + 1}`);

        if (this.viewIndx < this.viewIds.length - 1) {
          this.viewId = this.viewIds[++this.viewIndx];
        } else if (this.carIndx < this.cars.length - 1) {
          this.carIndx++;
          this.viewIndx = 0;
          this.viewId = this.viewIds[this.viewIndx];
        } else {
          this.restore();
        }
      });
    },
  },
};
</script>
